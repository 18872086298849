<template>
    <div class="topbar">
        <h1 @click="tabHandler('home')">{{ '<Manickam venkatachalam/>' }}</h1>
        <div class="menu" v-if="!$props.isMobile">  
            <p :class="{ active: activeTab === 'home' }" @click="tabHandler('home')"  >Home</p>
            <p :class="{ active: activeTab === 'about' }" @click="tabHandler('about')">About</p>
            <p :class="{ active: activeTab === 'techStack' }" @click="tabHandler('techStack')">TechStack</p>
            <p :class="{ active: activeTab === 'eduExperience' }" @click="tabHandler('eduExperience')">Expreience</p>
            <p :class="{ active: activeTab === 'whatIDo' }" @click="tabHandler('whatIDo')">Services</p>
            <p :class="{ active: activeTab === 'contactme' }" @click="tabHandler('contactme')">contact us</p>
        </div>
    </div>
    
</template>
<script>
    export default {
        name: 'topBar',
        emits: ['clickedTab'],
        props: ['isMobile'],
        data(){
            return {
                selectedTab: 'home'
            }
        },
        methods:{
            tabHandler(tabName){ 
                this.selectedTab = tabName;
                this.$emit('clickedTab', tabName);
            }
        }
    }
</script>
<style>
.topbar{
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    display: flex;
    flex-direction: row;
    color: #C7C7C7;
    background-color: black;
}

.topbar h1{
font-family: Bebas Neue;
    font-weight: 100;
    font-size: 28px;
    cursor: pointer;
}

.menu{
  display: flex;
  gap: 20px;
  font-family: Poppins;
  font-weight: 500;
  size: 16px;
}

.menu p { 
    cursor: pointer;
}


.menu p.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  display: block;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .menu {
        display: flex;
        gap: 14px;
        font-family: Poppins;
        font-weight: 500;
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .topbar{
        align-items: start;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        padding: 20px 0px;
        width: 100vw !important;
    }
    
    .topbar h1 {
        font-size:  26px;
        align-items: flex-start;
    }
}
</style>