<template>
    <div class="d-flex" style="flex-direction: column; max-width: 100%;">
        <div class="heading">
            <h1>
                WHo AM i
            </h1>
        </div>
        <div class="content">
            <span>
                As a Full Stack Software Development Engineer with over two years of experience, I specialize in
                creating comprehensive solutions that bridge front-end and back-end technologies, utilizing robust AWS
                cloud environments. My expertise extends deeply into DevOps practices, allowing for seamless integration
                and deployment processes that enhance product scalability and performance. I am committed to continuous
                improvement, consistently exploring the latest advancements in technologies to refine and advance my
                development projects.
            </span>
        </div>
        <div class="years_exp_span">
            <div class="experience">
                <div   class="experience_year"  >
                    <span
                       >02+</span>
                </div>
                <div style="" class="exp_year_div">
                    <span class="exp_year" style="flex-wrap: wrap;">
                        YEARS EXPERIENCE
                    </span>
                </div>
            </div>
            <div class="experience">
                <div  class="experience_year">
                    <span
                      >05+</span>
                </div>
                <div class="exp_year_div">
                    <span class="exp_year" style="flex-wrap: wrap;">
                        COMPLETED WORKS
                    </span>
                </div>
                
            </div>
        </div>
    </div>

</template>
<script>
import { defineComponent } from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
export default defineComponent({
    name: 'aboutMe',
    data() {
        return {
            
        }
    },
    mounted(){
        AOS.init({
            once: false,
            duration: 1000,
            offset: 100,
            easing: 'ease-in-out'
        })
    },
    updated() {
        AOS.refresh();
    }  
})
</script>
<style scoped>
.heading {
    font-family: Bebas Neue;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    justify-content: center;
}

.content span {
    font-family: Poppins;
    font-weight: 200;
    font-size: 22px;
    text-align: center;
    line-height: 2;
    color: #FFFFFFCC
}

.experience {
    height: 228px;
    max-width: 50%;
    display: flex;
    gap: 60px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #0A0A0A 0%, rgba(33, 32, 32, 0.6) 100%);
    border: solid 1px #FFFFFF33;
    border-radius: 10px;
}

.learnMore {
    height: 228px;
    width: 50%;
    display: flex;
    gap: 38px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #0A0A0A 0%, rgba(33, 32, 32, 0.6) 100%);
    border: solid 1px #FFFFFF33;
    border-radius: 10px;
}

.exp_year {
    font-family: Bebas Neue;
    font-weight: 400;
    font-size: 60px;
    line-height: 1;
}

.experience_year{
    font-weight: 400;
    font-size: 110px;
    text-align: center; 
    color: #D3E97A; 
    font-family: 'Inconsolata'; 
    margin-right: -10px;
}

.years_exp_span{
    display: flex;
    gap: 50px; 
    margin-top: 3rem; 
    min-width: 100%;

}

.exp_year_div{
    width: 40%; 
    text-align: start;
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .heading {
    
    font-size: 28px !important;
    
}

.experience_year{

    font-size: 80px;

}

.exp_year{
    font-size: 34px;
}

.content span{
    font-size: 14px;
}
.experience {
    height: 149px;
}
}

@media (max-width: 600px) {
    .content span {
        font-family: Poppins;
        font-weight: 200;
        font-size: 16px;
        text-align: left;
        line-height: 2;
        color: #FFFFFFCC
    }

    .years_exp_span {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 2rem;
    }

    .experience{
        height: auto;
        max-width: 100%;
        flex-direction: column;
        gap: 0px;
    }


    .experience_year {
        font-size: 68px;
        text-align: center;
    }

    .exp_year_div {
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
    }

    .exp_year {
        font-size: 38px;
    }

    .heading {
        font-size: 24px !important;
    }
    
}
</style>