<template>
    <div class="d-flex tech-main-container" >
        <div class="heading">
            <h1>
                My Tech Stack
            </h1>
        </div>
        <div class="tec_stack_heading"
        >
            <p> Technologies I’ve been working with recently</p>
        </div>
        <div style="" class="techstack-parent-container">
            <div>
                <p class="techonologiesHeading">FrontEnd Technologies</p>
                <div class="tech_img_parent">
                    <div v-for="value of frontendTechonolgies" :key="value.name">
                        <div class="tech_img">
                        <img style="width: 42px;" :src="require(`@/assets/${value.greyedImg}.svg`)"/>
                        </div>

                        <p class="techStackName">{{ value.name }}</p>
                    </div>
                </div>
            </div>
            <div>
                <p class="techonologiesHeading">BackEnd Technologies</p>
                <div class="tech_img_parent">
                    <div v-for="value of backendTechonolgies" :key="value.name">
                      <div class="tech_img">
                    <img style="width: 42px;" :src="require(`@/assets/${value.greyedImg}.svg`)"/>
                    </div>
                        <p class="techStackName">{{ value.name }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex otherToolsTechDiv" style="">
            <p class="techonologiesHeading otherToolsTech" style="">Other Tools & Technologies</p>
            <div class="tech_img_parent" style="max-width: 100%;">
                <div v-for="value of otherTools" :key="value.name" >
                    <div class="tech_img">
                    <img style="width: 42px;" :src="require(`@/assets/${value.greyedImg}.svg`)"/>
                    </div>
                    <p class="techStackName" style="word-wrap: break-word;">{{ value.name }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {defineComponent} from 'vue'
export default defineComponent ({
    name: 'techStack',
    data(){
        return {
            frontendTechonolgies: [
                {
                    greyedImg: 'vuejs_greyed',
                    name: 'VUE' 
                },
                {
                    greyedImg: 'vuex_greyed',
                    name: 'VUEX' 
                },{
                    greyedImg: 'vuteify_greyed',
                    name: 'Vuteify' 
                },{
                    greyedImg: 'react_greyed',
                    name: 'React' 
                }
            ],
            backendTechonolgies: [
                
                {
                    greyedImg: 'express_greyed',
                    name: 'Express' 
                },
                {
                    greyedImg: 'nodejs_greyed',
                    name: 'NodeJS' 
                },
                {
                    greyedImg: 'dynamoDB_greyed',
                    name: 'DynamoDB' 
                },
                {
                    greyedImg: 'mongoDB',
                    name: 'MongoDB' 
                }
            ],
            otherTools: [
                
                {
                    greyedImg: 'aws_greyed',
                    name: 'AWS Cloud' 
                },
                {
                    greyedImg: 'docker_greyed',
                    name: 'Docker' 
                },
                {
                    greyedImg: 'aws-lambda_greyed',
                    name: 'Lambda' 
                },
                {
                    greyedImg: 'serverless_greyed',
                    name: 'Serverless Tech'
                }
            ]
        }
        
    },
    mounted(){

    }
})
</script>
<style>
.heading {
    font-family: Bebas Neue;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tech-main-container{
    flex-direction: column; width: 100%; justify-content: center;
}
.tec_stack_heading{
 font-family: poppins;
            font-weight: 100;
            font-size: 32px;
            color: #A7A7A7;
            text-align: center;
}

.techStackName{
    display: flex; justify-content: center; font-family: Thin 100; font-size: 12px; line-height: 26px; text-align: center;
    color: #A7A7A7;
}
.techonologiesHeading{
    font-weight: 400;
    font-size: 32px; 
    align-items: center;
    color: #FFFFFFCC;
}

.tech_img_parent{
    display: flex; 
    gap: 31px; 
    align-items: center; 
    justify-content: center; 
    margin-top: 30px; 
    flex-wrap: wrap;
}

.tech_img{
    height: 54px;
     display: flex;
     align-content: center;
     justify-content:center;
}

.techstack-parent-container {
    font-family: poppins;
    justify-content: space-between;
    display: flex;
    margin-top: 8%
}

.otherToolsTech{
    justify-content: center; align-items: center;
}

.otherToolsTechDiv{
            flex-direction: column; align-items: center; margin-top: 5%;
        }
@media screen and (min-width: 768px) and (max-width: 1024px){
.tec_stack_heading{
    font-size: 28px;
}
.techonologiesHeading{
    font-size: 28px !important;
}
.tech_img{
    height: 45px;
       display: flex;
     align-content: center;
     justify-content:center;
}
.tech_img_parent{
    display: flex; 
    gap: 27px !important; 
    align-items: center; 
    justify-content: center; 
    margin-top: 30px; 
    flex-wrap: wrap;
}



}

@media (max-width: 600px) {
    .tech-main-container {
        justify-content: flex-start;
        margin-top: 4rem;
    }

    .heading{
        font-size: 22px;
        justify-content: center;
    }

    .tec_stack_heading{
        font-size: 16px;
    }

    .techstack-parent-container{
        display: flex;
        flex-direction: column;
    }

    .techonologiesHeading{
        align-items: flex-start;
        font-size: 16px;
        margin-top: 30px;
    }

    .tech_img_parent {
        margin-top: 10px;
        gap: 18px;
        justify-content: flex-start;
    }

        .otherToolsTech {
            justify-content: center;
            align-items: center;
        }

        .otherToolsTechDiv{
             align-items: start; 
             margin-top: 5%;
        }
}

</style>