<template>
    <div class="d-flex" style="flex-direction: column; width: 100%; justify-content: center;">
        <div class="heading">
            <h1>
                My Tech Stack
            </h1>
        </div>
        <div style="font-family: poppins;
            font-weight: 100;
            font-size: 32px;
            color: #A7A7A7;
            text-align: center;"
        >
            <p> Technologies I’ve been working with recently</p>
        </div>
        <div style="font-family: poppins; justify-content: space-between; display: flex; margin-top:8%">
            <div>
                <p class="techonologiesHeading">FrontEnd Technologies</p>
                <div style="display: flex; gap: 31px; align-items: center; justify-content: center; margin-top: 20px; flex-wrap: wrap;">
                    <div v-for="value of frontendTechonolgies" :key="value.name">
                        <img style="height: 54px; width: 54px;" :src="require(`@/assets/${value.greyedImg}.svg`)"/>
                        <p class="techStackName">{{ value.name }}</p>
                    </div>
                </div>
            </div>
            <div>
                <p class="techonologiesHeading">BackEnd Technologies</p>
                <div style="display: flex; gap: 31px; align-items: center; justify-content: center; margin-top: 30px; flex-wrap: wrap;">
                    <div v-for="value of backendTechonolgies" :key="value.name">
                        <img style="height: 54px; width: 54px;" :src="require(`@/assets/${value.greyedImg}.svg`)"/>
                        <p class="techStackName">{{ value.name }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex" style="flex-direction: column; align-items: center; margin-top: 5%;">
            <p class="techonologiesHeading" style="justify-content: center; align-items: center;">Other Tools & Technologies</p>
            <div style="display: flex; gap: 31px; align-items: center; justify-content: center; margin-top: 30px; flex-wrap: wrap;">
                <div v-for="value of otherTools" :key="value.name" style="justify-content: center; display: flex; align-items: center; flex-direction: column;">
                    <img style="height: 54px; width: 54px;" :src="require(`@/assets/${value.greyedImg}.svg`)"/>
                    <p class="techStackName">{{ value.name }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {defineComponent} from 'vue'
export default defineComponent ({
    name: 'techStack',
    data(){
        return {
            frontendTechonolgies: [
                {
                    greyedImg: 'vuejs_greyed',
                    name: 'VUE' 
                },
                {
                    greyedImg: 'vuex_greyed',
                    name: 'VUEX' 
                },{
                    greyedImg: 'react_greyed',
                    name: 'React' 
                },{
                    greyedImg: 'vuteify_greyed',
                    name: 'Vuteify' 
                }
            ],
            backendTechonolgies: [
                
                {
                    greyedImg: 'express_greyed',
                    name: 'Express' 
                },
                {
                    greyedImg: 'nodejs_greyed',
                    name: 'NodeJS' 
                },
                {
                    greyedImg: 'dynamoDB_greyed',
                    name: 'DynamoDB' 
                },
                {
                    greyedImg: 'mongoDB',
                    name: 'MongoDB' 
                }
            ],
            otherTools: [
                
                {
                    greyedImg: 'aws_greyed',
                    name: 'AWS Cloud' 
                },
                {
                    greyedImg: 'docker_greyed',
                    name: 'Docker' 
                },
                {
                    greyedImg: 'aws-lambda_greyed',
                    name: 'Lambda' 
                },
                {
                    greyedImg: 'serverless_greyed',
                    name: 'Serverless Techonlogies'
                }
            ]
        }
        
    },
    mounted(){

    }
})
</script>
<style>
.heading {
    font-family: Bebas Neue;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    display: flex;
    align-items: center;
}
.techStackName{
    display: flex; justify-content: center; font-family: Thin 100; font-size: 12px; line-height: 26px; text-align: center;
    flex-wrap: wrap;
    color: #A7A7A7;
}
.techonologiesHeading{
    font-weight: 400; font-size: 32px; align-items: center;
    color: #FFFFFFCC;
}
</style>