<template>
    <div class="title_heading ">
        <h1>
            What I DO
        </h1>
    </div>
    <div class="section_description" >
        <p> {{`Bring your imaginary ideas to real via
            <web /> and Mobile Apps development, creating robust, efficent and user friendly solutions`}}
        </p>
    </div>
    <div class="services-container">
        <div v-for="(item, index) of items" :key="index" style="width: 50%">
            <div class="d-flex pa-4 service-item" style="">
                <h5 class="service_headers" >{{ item.title }}</h5>
                <span style="font-family: poppins; font-weight: 300; font-size: 20px; color: #FFFFFFCC;"> {{ item.description }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
    name: 'whatIDo',
    data() {
        return {
            items: [
                {
                    title: 'Web App Development',
                    description: `Building dynamic, responsive websites and web applications, delivering user-friendly interfaces and robust back-end solutions.`
                },
                {
                    title: ' Mobile App Development',
                    description: `Crafting high-performance mobile apps for iOS and Android, ensuring smooth functionality and an intuitive user experience.`
                },{
                    title: 'DevOps',
                    description: `Streamlining development and operations through automated CI/CD pipelines, enhancing efficiency, and reducing time to market.`
                },{
                    title: 'AWS Architecture Design',
                    description: `Designing scalable, secure cloud architectures on AWS, optimizing performance and cost-effectiveness for your business needs.`
                },
            ]
        }
    }
});
</script>
<style scoped>
.title_heading {
    font-family: Bebas Neue;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    justify-content: center;
}

.services-container {
  display: flex;
  flex-wrap: wrap;  /* Ensures items can wrap onto the next line */
  width: 100%;
  justify-content: space-between;
}

.services-container .div {
    width: 50%;
}
.service-item {
    flex: 1 0 50%;
    margin-bottom: 20px;
    flex-direction: column;
    border: 1px solid #FFFFFF33;
    background: linear-gradient(180deg, #0A0A0A 0%, rgba(33, 32, 32, 0.6) 100%);
    border-radius: 20px;
    margin: 20px;
}
.section_description{
font-family: poppins;
            font-weight: 100;
            font-size: 32px;
            color: #A7A7A7;
            filter: grayscale(100%);
            text-align: center;
}

.service_headers{
     color: #D3E97A; 
     font-family: Poppins; 
     font-weight: 500; 
     font-size: 24px; 
     margin-bottom: 10px;
}

@media screen and (min-width: 768px) and (max-width: 1024px){
.title_heading {
    font-family: Bebas Neue;
    font-weight: 400;
    font-size: 28px !important;
    text-align: center;
    justify-content: center;
}

.section_description{
font-family: poppins;
            font-weight: 100;
            font-size: 26px;
            color: #A7A7A7;
            filter: grayscale(100%);
            text-align: center;
}

.service_headers{
     color: #D3E97A; 
     font-family: Poppins; 
     font-weight: 500; 
     font-size: 20px; 
     margin-bottom: 10px;
}

}

@media (max-width: 600px) {
  .service-item {
    margin: 10px 0px;
  }

  .services-container div{
    width: 100% !important;
  }
  .title_heading {
    font-size: 18px;
    margin-top: 3rem;
  }

  .section_description {
      font-size: 16px;
      margin-bottom: 1.5rem;
  }

  .services-container {
      flex-direction: column;
  }

  .service_headers {
      font-size: 18px !important;
  }

  .service-item span{
    font-size: 14px !important;
  }
}

</style>