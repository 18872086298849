<template>
    <div class="title_heading">
        <h1>
            EDUCATION and Exprerience
        </h1>
    </div>
    <v-container class="parent_div">
      <v-timeline align-top dense side="end" class="v-timeline">
        <v-timeline-item
          v-for="(item, index) in items"
          :key="index"
          :color="item.color"
          :dot-color="item.color"
          :icon="item.icon"
          fill-dot
          hide-opposite="true"
        >  
            <div class="d-flex pa-4 timelineContent" style="">
            <div class="d-flex timelineHeading" style="">
                <h4 class="title">{{ item.title }}</h4>
                <span class="description pt-3">{{ item.period }}</span>
            </div>
            <span v-html="item.description" class="heading_description"></span>
            <p class="roleHeading pt-6" style="color: #FFFFFFB2">{{ item.detailed_description }}</p>
            </div>
        </v-timeline-item>
      </v-timeline>
    </v-container>
  </template>
  
<script>
import '@mdi/font/css/materialdesignicons.css'

  export default {
    name: 'TimelineComponent',
    data() {
      return {
        items: [
          {
            title: 'Software Development Engineer -1',
            description: `Currently Working as Full Stack Developer <span class='heading_description_highlight'> @codestax.ai. </span>`,
            detailed_description: 'Creating applications backend using node, express. Developing responsive web applications using vue, vuex, JS, TS. Developing scalable and robust web applications using serverless technologies, AWS, microservice and event driven architecture',
            period: 'JUN 2022 - Present',
            color: 'white',
            icon: 'mdi-pause',
          },
          {
            title: 'DevOps Engineering',
            description: `Worked as infrastructure engineer <span class='heading_description_highlight'> @pranion </span>`,
            detailed_description: 'Infrastructure and DB setup for projects in AWS and other cloud environments. Hoisting and writing bash scripts for automating manual installation process',
            period: 'APR 2022 - JUN 2022',
            color: 'white',
            icon: 'mdi-checkbox-marked-circle',
          },
          {
            title: 'BE - Electronics and Communication Engineering',
            description: `Studied <span class='heading_description_highlight'> @Kalasalingam Institution of Technology </span>`,
            period: 'SEP 2018 - JUN 2022',
            color: 'white',
            icon: 'mdi-school',
          },
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  .timeline-container {
    position: relative;
    padding-left: 50px;
    list-style: none;
  }

  .timeline-item {
    margin-bottom: 20px;
  }

  .timeline-marker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    width: 5px;
    background-color: #D9D9D999;
  }

  .timeline-content {
    margin-left: 40px;
    padding: 10px;
    background-color: linear-gradient(180deg, #0A0A0A 0%, rgba(33, 32, 32, 0.6) 100%);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #FFFFFF33;
    border-radius: 20px
  }

  .heading {
    font-family: Bebas Neue;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    justify-content: center;
  }

  .experienceDetails {
    height: 211px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    justify-content: center;
    background: linear-gradient(180deg, #0A0A0A 0%, rgba(33, 32, 32, 0.6) 100%);
    border: solid 1px #FFFFFF33;
    border-radius: 10px;
  }

  .roleHeading {
    display: flex;
    gap: .5rem;
    align-items: center;
  }

  .title {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
  }

  .roleHeading {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
  }

  .description {
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    color: #FFFFFF99;
  }

  .pause-icon {
    width: 50px;
    height: 50px;
    background-color: fff;
    border: 1px solid transparent;
    border-radius: 50px;
  }

  .heading_description {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #FFFFFFB2;
  }

  .heading_description_highlight {
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 26px;
    color: #fff;
  }

  .title_heading {
    font-family: Bebas Neue;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    justify-content: center;
  }

  .timelineContent {
    flex-direction: column;
    border: 1px solid #FFFFFF33;
    background: linear-gradient(180deg, #0A0A0A 0%, rgba(33, 32, 32, 0.6) 100%);
    border-radius: 20px;
  }

  .timelineHeading {
    justify-content: space-between;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .title_heading {
      font-family: Bebas Neue;
      font-weight: 400;
      font-size: 28px !important;
      text-align: center;
      justify-content: center;
    }

    .title {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
    }

    .description {
      font-family: 'Poppins';
      font-weight: 300;
      font-size: 14px;
      line-height: 26px;
      color: #FFFFFF99;
    }

    .heading_description {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      color: #FFFFFFB2;
    }

    .roleHeading {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 17px;
      line-height: 26px;
    }
  }

  @media (max-width: 600px) {
    .title_heading {
      margin-top: 4rem;
      font-size: 16px;

    }

    .v-timeline--vertical.v-timeline.v-timeline--side-end .v-timeline-item .v-timeline-item__body {
      padding-inline-start: 10px !important;
    }

    .parent_div {
      margin-left: -58px;
    }

    .timelineContent {
      flex-direction: column;
      border: 1px solid #FFFFFF33;
      background: linear-gradient(180deg, #0A0A0A 0%, rgba(33, 32, 32, 0.6) 100%);
      border-radius: 20px;
      width: 140% !important;
    }

    .timelineHeading {
      flex-direction: column;
    }

    .description {
      font-size: 14px;
      line-height: 26px;
      padding-top: 0px !important;
    }

    .heading_description {
      font-size: 14px;
      line-height: 16px;
      padding-top: 4px !important;
    }

    .roleHeading {
      font-size: 12px;
      line-height: 18px;
      padding-top: 12px !important;
    }

    .title {
      font-size: 14px;
      line-height: 18px;
    }
  }

</style>
<style>
.v-timeline-item__body{
    width: 100% !important;
}
.v-timeline-line-thickness {
    width: 6px;
}
</style>
  