<template>
    <div class="d-flex" style="width: 100%;">
        <div style="width: 50%; flex-direction: column; align-items: start; justify-content: center" class="d-flex">
            <span class="ideaText">Have a Idea In Mind ? </span>
            <span class="ideaText">Let’s Get To WOrk 👋</span>
            <span style="font-family: Bebas Neue; font-weight: 500; font-size: 24px; display: flex;">
                Fill the form or reach me at
                <a href="mailto:hello@manickam.me"
                    style="color: #D3E97A; margin: 0px 6px;">hello(at)manickam(dot)me</a>!
            </span>
        </div>
        <div style="width: 50%; flex-direction: column; justify-content: center" class="d-flex">
            <div v-if="!isFormSubmittedSuccesfully">
                <v-text-field label="Name" variant="outlined" v-model="form.name"
                    :error-messages="errors.name"></v-text-field>

                <v-text-field label="E-Mail" variant="outlined" v-model="form.email"
                    :error-messages="errors.email"></v-text-field>

                <v-text-field label="Phone Number" variant="outlined" v-model="form.phone" type="number"
                    :error-messages="errors.phone"></v-text-field>

                <v-textarea label="Message" variant="outlined" v-model="form.message"
                    :error-messages="errors.message"></v-textarea>
                <div style="align-items: flex-end; display: flex; justify-content: flex-end; width: 100%;">
                    <v-btn @click="sendEmail" :disabled="submitLoader"
                        style="background: #D3E97A; color: black; border-radius: 100px; height: 54px; width: auto; margin-top: 10px; font-family: Poppins; width: 167px">
                        {{ submitLoader ? 'Submitting...' : 'Submit' }}
                    </v-btn>
                </div>
            </div>

            <div v-else
                style="align-items: center; justify-content: center; height: 48vh; display: flex; flex-direction: column;">
                <img style="width: 30%" :src="require('@/assets/Animation-1731009480634.gif')" />
                <span style="font-family: poppins;">Details has been submitted Successfully</span>
            </div>
        </div>
    </div>
    <div class="bottom-bar">
        <div class="socialMedia">
            <span style="font-family: poppins; font-weight: 500; font-size: 24px; text-align: center;">To learn more
                about me</span>
            <div style="display: flex; gap: 100px; cursor: pointer;">
                <a href="https://www.linkedin.com/in/manickam-venkatachalam/" target="_blank">
                    <img :src="require('@/assets/linkedIN.svg')" alt="LinkedIn" />
                </a>
                <a href="https://www.instagram.com/manickam_here/" target="_blank">
                    <img :src="require('@/assets/instagram.svg')" alt="Instagram" />
                </a>
                <a href="https://discordapp.com/users/manickam_" target="_blank">
                    <img :src="require('@/assets/discord.svg')" alt="Discord" />
                    <!-- Ensure you have a Discord icon asset -->
                </a>
                <a href="https://github.com/Manickam-venkatachalam" target="_blank">
                    <img :src="require('@/assets/github.svg')" alt="GitHub" />
                </a>
            </div>
        </div>
        <div class="handCrafted">
            <span>© 2024 | Handcrafted with 🤍 by Manickam</span>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import emailjs from 'emailjs-com';
export default defineComponent({
    name: 'contactUs',
    data() {
        return {
            letsTalkBtnHover: false,
            form: {
                name: '',
                email: '',
                phone: '',
                message: '',
                from_name: 'manickam.me'
            },
            errors: {
                name: null,
                email: null,
                phone: null,
                message: null
            },
            isFormSubmittedSuccesfully: false,
            submitLoader: false
        }
    },
    methods: {
        validateForm() {
            let valid = true;
            if (!this.form.name) {
                this.errors.name = 'Name is required.';
                valid = false;
            } else {
                this.errors.name = null;
            }

            if (!this.form.email) {
                this.errors.email = 'Email is required.';
                valid = false;
            } else if (!/\S+@\S+\.\S+/.test(this.form.email)) {
                this.errors.email = 'Email is invalid.';
                valid = false;
            } else {
                this.errors.email = null;
            }

            if (!this.form.phone) {
                this.errors.phone = 'Phone number is required.';
                valid = false;
            } else if (!/^\d{10}$/.test(this.form.phone)) { // Adjust regex as needed
                this.errors.phone = 'Phone number is invalid.';
                valid = false;
            } else {
                this.errors.phone = null;
            }

            if (!this.form.message) {
                this.errors.message = 'Message is required.';
                valid = false;
            } else {
                this.errors.message = null;
            }

            return valid;
        },
        sendEmail() {
            if (this.validateForm()) {
                this.submitLoader = true;
                emailjs.send('service_injq8hq', 'template_qjemdaw', this.form, 'NoTdua5fgwBN94wtc')
                    .then((response) => {
                        console.log('FORM SUBMITTED SUCCESSFULLY!', response.status, response.text);
                        this.isFormSubmittedSuccesfully = true;
                    }, (error) => {
                        console.error('FORM SUBMISSION FAILED...', error);
                        alert('Error while sending message. Please try again later.');
                    })
                    .finally(() => {
                        this.submitLoader = false;
                    });
            }
        }
    }
});
</script>
<style scoped>
.ideaText {
    font-family: Bebas Neue;
    font-weight: 400;
    font-size: 70px;
    text-align: start;
    line-height: 64px;
}


.socialMedia {
    display: flex;
    gap: 38px;
    line-height: 12px;
    margin-top: 32px;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
}

.bottom-bar {
    display: flex;
    align-items: end;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
    border-top: 1px solid #484848;
}

.handCrafted {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
}
</style>
<style>
.v-messages__message {
        line-height: 10px;
        margin-bottom: 16px;
    }
</style>