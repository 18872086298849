<template>
    <div class="home_layout">
        <div style="align-items: center;" class="content_div">
            <div class="Vanakkam">
                <span class="vanakkam_text">Vanakkam(🙏);</span>
                <span class="Im_text">I'm</span>
            </div>
            <h1 class="name">Manickam.</h1>
            <div class="domain_text">
                <span>Full Stack Developer | DevOps</span>
            </div>
            <div class="tagLine">
                <p>Step into my codeverse, where every semicolon smiles and bugs begone! 😄🐞</p>
            </div>
            <div class="connection_section">
                <v-btn
                    class="buttonStyle"
                    @mouseover="letsTalkBtnHover = true" @mouseleave="letsTalkBtnHover = false"
                    @click="movetoContactUS()">
                    Let's Talk
                    <template v-slot:append>
                        <v-icon v-if="letsTalkBtnHover">{{ "mdi-arrow-top-right" }}</v-icon>
                        <v-icon v-else>{{ "mdi-checkbox-blank-circle" }}</v-icon>
                    </template>
                </v-btn>
                <v-btn
                    class="buttonStyle"
                    @mouseover="downloadBtnHoverd = true" @mouseleave="downloadBtnHoverd = false"
                    @click="downloadResume()">
                    {{isMobile ? 'Download Resume' : 'Resume'}}
                    <template v-slot:append>
                        <v-icon v-if="!downloadBtnHoverd">{{ "mdi-checkbox-blank-circle" }}</v-icon>
                        <img v-else :src="require('@/assets/download_icon.svg')" alt="Download Icon"
                            style="height: auto; width: 40px; margin-left: 10px;">
                    </template>
                </v-btn>
            </div>

        </div>
        <div>
            <span class="tagIcon" v-if="!isMobileView">{{ '</>' }}</span>
            <img :src="require('@/assets/Home_Page_Img.svg')" class="profile_img" />
            <img   v-if="!isMobileView" class="stackIcon" :src="require('@/assets/stackIcon.svg')" />
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
export default defineComponent({
    name: 'myHome',
    emits: ['movetoContactUS'],
    props: ['isMobileView'],
    data() {
        return {
            downloadBtnHoverd: false,
            letsTalkBtnHover: false
        }
    },

    mounted() {
        AOS.init({
            once: false,
            duration: 1000,
            offset: 100,
            easing: 'ease-in-out'
        })
    },

    methods: {
        movetoContactUS() {
            this.$emit('movetoContactUS')
        },
        downloadResume() {
            window.open('https://drive.google.com/file/d/1cX3JJhQYyOaoczQs5muthyELoTNn0Vgx/view?usp=sharing', '_blank');
        }
    },

    updated() {
        AOS.refresh();
    }
});
</script>
<style>
.home_layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 20px; */
    width: 100%;
}



.Vanakkam {
    display: flex;
    gap: 8px;
}

.vanakkam_text {
    font-family: 'Fira Code';
    font-weight: 600;
    font-size: 32px;
}

.Im_text {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 32px;
}

.name {
    font-family: 'Bebas Neue';
    font-weight: 100;
    font-size: 100px;
    margin-top: 0;
    line-height: 1;
}

.domain_text {
    font-family: 'poppins';
    font-size: 32px;
    line-height: 1.2;
    font-weight: 500;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.tagLine {
    font-family: 'poppins 100', sans-serif;
    font-weight: 100;
    font-size: 30px;
    line-height: 35px;
    color: #C7C7C7;
}

.profile_img {
    height: 24rem;
    width: 24rem
}

.tagIcon {
    font-family: 'Fira Code';
    color: #FFFFFF80;
    font-weight: 600;
    font-size: 50px;
    letter-spacing: 1px;
    transform: rotate(-18deg);
    margin-top: -56px;
    margin-right: -96px;
}

.stackIcon {
    width: 3.2rem;
    position: absolute;
    bottom: -40px;
    right: -15px;
}

.connection_section {
    align-items: center;
    gap: 20px;
    display: flex;
}

.buttonStyle {
    background: #D3E97A;
    color: black !important;
    border-radius: 100px;
    height: 54px !important;
    width: auto;
    margin-top: 42px;
    font-family: Poppins;
}
.content_div{
    align-items: center;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .vanakkam_text {
        font-size: 22px;
    }

    .Im_text {
        font-size: 22px;
    }

    .name {
        font-size: 70px;

    }

    .domain_text {
        font-size: 23px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .tagLine {
        font-size: 20px;
        line-height: 30px;
    }

    .tagIcon {

        font-weight: 600;
        font-size: 30px;
        letter-spacing: 1px;
        transform: rotate(-18deg);
        margin-top: -56px;
        margin-right: -96px;
    }

    .profile_img {
        height: 20rem;
        width: 20rem;
    }

    .stackIcon {
        width: 2.5rem;
        position: absolute;
        bottom: -40px;
        right: -15px;
    }

    .home_layout {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1px;
        width: 100%;
    }

    .v-container.fill-height {
        align-items: flex-start !important;
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .home_layout>div {
        flex: 1;
    }

    .home_layout>div:last-child {
        justify-content: flex-end;
        display: flex;
    }
}

@media (max-width: 600px) {
    .home_layout {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 5rem;
        padding-left: 0px;
        padding-right: 0px;
    }

    .vanakkam_text {
        font-size: 24px;
    }

    .Im_text {
        font-size: 24px;
    }

    .name {
        font-size: 68px;
    }
    
    .domain_text {
        font-size: 20px;
        margin-top: .5rem;
        margin-bottom: 1.5rem;
    }

    .tagLine {
        font-size: 22px;
    }

    .buttonStyle {
        height: 42px !important;
        width: auto !important;
        margin-top: 36px;
        
    }

    .connection_section {
        gap: 12px;
        width: 100%;
    }

    .connection_section .v-btn{
        width: 50% !important;
    }

    .profile_img {
        margin-top: 2rem;
        padding: 5px;
        margin-bottom: 2rem;
    }

    .content_div{
        width: 100%;
    }

    .profile_img {
        height: 20rem;
        width: 20rem;
    }

    
    

}
</style>